import {SiteConfigContextValue} from '@common/core/settings/site-config-context';
import {RegisterPageFields} from '@app/register-page-fields';
import {message} from '@common/i18n/message';
import {EnvatoIcon} from '@common/icons/social/envato';
import {getBootstrapData} from '@common/core/bootstrap-data/use-backend-bootstrap-data';
import {AccountDashboardPurchasesPanel} from './account/account-dashboard/account-dashboard-purchases-panel';
import {AccountDashboardTicketsPanel} from './account/account-dashboard/account-dashboard-tickets-panel';
import {FolderIcon} from '@common/icons/material/Folder';

const accountDashboardPanels = [];
accountDashboardPanels.push({
  icon: FolderIcon,
  label: message('Tickets'),
  component: AccountDashboardTicketsPanel,
  id: 'tickets',
});
if (getBootstrapData().settings.envato?.enable) {
  accountDashboardPanels.push({
    icon: EnvatoIcon,
    label: message('Licenses'),
    component: AccountDashboardPurchasesPanel,
    id: 'purchases',
  });
}

export const SiteConfig: Partial<SiteConfigContextValue> = {
  auth: {
    redirectUri: '/',
    secondaryRedirectUri: '/docs',
    adminRedirectUri: '/admin',
    registerFields: RegisterPageFields,
    accountSettingsPanels: [],
    accountDashboardPanels,
  },
  homepage: {
    options: [
      {
        label: message('Homepage'),
        value: 'homePage',
      },
      {
        label: message('Help Center Landing page'),
        value: 'landingPage',
      },
    ],
  },
  admin: {
    ads: [],
  },
  tags: {
    types: [{name: 'category'}, {name: 'status'}],
  },
  demo: {
    loginPageDefaults: 'singleAccount',
    email: 'admin@demo.com',
    password: 'demo',
  },
  settings: {
    showIncomingMailMethod: true,
  },
};
