import {keepPreviousData, useQuery} from '@tanstack/react-query';
import {apiClient} from '@common/http/query-client';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {License} from '@app/agent/license';
import {
  LengthAwarePaginationResponse,
  PaginationResponse,
} from '@common/http/backend-response/pagination-response';

export interface PaginatePurchasesParams {
  page?: string | number;
  perPage?: string | number;
  userId?: string | number;
  query?: string | null;
  paginate?: 'simple';
  loader?: 'basic' | 'ticketTable';
  orderBy?: string;
  orderDir?: string;
}

interface Response extends BackendResponse {
  pagination: LengthAwarePaginationResponse<License>;
}

export function useUserPurchases(
  userId: number | string,
  params?: PaginatePurchasesParams,
) {
  return useQuery({
    queryKey: ['purchases', userId, params],
    queryFn: () => fetchPurchases(userId, params),
    placeholderData: keepPreviousData,
  });
}

export function useUserDashboardPurchases(
  userId: number | string,
  params?: PaginatePurchasesParams,
) {
  return useQuery({
    queryKey: ['account-dashboard-purchases', userId],
    queryFn: () => fetchPurchases(userId, params),
    placeholderData: keepPreviousData,
  });
}

function fetchPurchases(
  userId: number | string,
  params?: PaginatePurchasesParams,
) {
  return apiClient
    .get<Response>(`users/${userId}/purchases`, {params})
    .then(response => response.data);
}
