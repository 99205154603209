import {useSettings} from '@common/core/settings/use-settings';
import {Footer} from '@common/ui/footer/footer';
import React, {ReactNode, useCallback} from 'react';
import {PageStatus} from '@common/http/page-status';
import {Navbar} from '@common/ui/navigation/navbar/navbar';
import {HcSearchBar} from '@app/help-center/search/hc-search-bar';
import {useHomePage} from './use-home-page';
import {Button} from '@common/ui/buttons/button';
import {Trans} from '@common/i18n/trans';
import {CustomPage} from '@common/admin/custom-pages/custom-page';
import {Item} from '@app/agent/item';
import {KeyboardArrowRightIcon} from '@common/icons/material/KeyboardArrowRight';
import {KeyboardArrowLeftIcon} from '@common/icons/material/KeyboardArrowLeft';
import {FlagIcon} from '@common/icons/material/Flag';
import {Link} from 'react-router-dom';
import {IconButton} from '@common/ui/buttons/icon-button';
import clsx from 'clsx';
import {AnimatePresence, m} from 'framer-motion';
import {PageMetaTags} from '@common/http/page-meta-tags';

export function HomePage() {
  const query = useHomePage();

  return (
    <Layout>
      {query.data ? (
        <>
          <PageMetaTags query={query} />
          <Newsticker items={query.data.pages} />
          <div className="px-12 md:px-24">
            <main className="relative z-10 py-24">
              <ItemsList items={query.data.items} />
            </main>
          </div>
        </>
      ) : (
        <div className="flex h-screen items-center justify-center bg-paper">
          <PageStatus
            query={query}
            show404={false}
            delayedSpinner={false}
            loaderIsScreen={false}
          />
        </div>
      )}
    </Layout>
  );
}

interface LayoutProps {
  children: ReactNode;
}
function Layout({children}: LayoutProps) {
  const {home} = useSettings();
  return (
    <div className="isolate bg-alt">
      <Navbar menuPosition="header">
        <HcSearchBar />
      </Navbar>
      {children}
      {home?.show_footer && <Footer />}
    </div>
  );
}

function ItemsList({items}: {items: Item[]}) {
  return (
    <div className="grid max-w-full gap-20 md:grid-cols-2 lg:grid-cols-3 lg:gap-40">
      {items.map(item => (
        <div
          key={item.slug}
          className="flex w-full min-w-1 cursor-pointer flex-col rounded-lg  bg-paper shadow transition-shadow duration-300 hover:shadow-xl"
        >
          <div className="relative flex items-center px-16 py-12">
            <img
              src={item.icon}
              width={32}
              height={32}
              className="mr-8 h-32 w-32 flex-shrink-0 rounded"
            />
            <h3 className="truncate text-sm font-semibold" title={item.name}>
              {item.name}
            </h3>
            <span className="ml-auto"></span>
            <Button
              variant="flat"
              color="accent"
              size="xs"
              href={item.sale_url}
              target="_blank"
            >
              <Trans message="BUY NOW" />
            </Button>
          </div>
          <div className="w-full bg-alt">
            <a href={item.website} target="_blank" rel="noreferrer">
              <img src={item.splash} className="w-full max-w-full" />
            </a>
          </div>
          <div>
            <ul className="flex-nowra grid list-none grid-cols-3 divide-x-1 py-14 text-center text-sm font-semibold leading-loose">
              <li>
                <a href={item.website} target="_blank" rel="noreferrer">
                  <Trans message="Demo" />
                </a>
              </li>
              {item.doc_url && (
                <li>
                  {item.doc_url.startsWith('http') ? (
                    <a
                      href={item.doc_url}
                      target={
                        item.doc_url.startsWith('http') ? '_blank' : '_self'
                      }
                      rel="noreferrer"
                    >
                      <Trans message="Documentation" />
                    </a>
                  ) : (
                    <Link to={item.doc_url}>
                      <Trans message="Documentation" />
                    </Link>
                  )}
                </li>
              )}
              <li>
                <a href="/account/tickets/new">
                  <Trans message="Support" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
}

function Newsticker({items}: {items: CustomPage[]}) {
  const [index, setIndex] = React.useState(1);

  const checkCurrentItem = (i: number) => index === i;
  const disablePrev = index === 1;
  const disableNext = index === items.length; // Update this line

  const changeSliderItem = (delta: number) => {
    if ((disablePrev && delta === -1) || (disableNext && delta === 1)) {
      return;
    }

    // some checks
    setIndex(index + delta);
  };

  return (
    <div className="flex h-48 w-full items-center bg shadow-md sm:h-60">
      <div className="hidden h-48 items-center border-r px-24 text-muted sm:flex">
        <FlagIcon className="flex-shrink-0 text-muted" />
      </div>
      <div className="relative flex min-w-0 flex-1 items-center pl-24 pr-10">
        <div className="pl-15 mr-auto h-40 min-w-0 flex-1">
          <AnimatePresence mode="sync">
            {items.map((item, index) => (
              <div key={item.id}>
                {checkCurrentItem(index + 1) && (
                  <m.div
                    className={clsx('max-w-full truncate leading-10')}
                    hidden={!checkCurrentItem(index + 1)}
                    initial={{x: '30px', opacity: 0}}
                    animate={{x: 0, opacity: 1}}
                    exit={{x: '-30px', opacity: 0}}
                  >
                    <span className="mr-12 rounded-full bg-danger px-12 py-2 text-sm text-white">
                      <Trans message="Info" />
                    </span>
                    <Link
                      to={`/pages/${item.slug}`}
                      className=" text-sm hover:underline"
                    >
                      {item.title}
                    </Link>
                  </m.div>
                )}
              </div>
            ))}
          </AnimatePresence>
        </div>
        <div className="ml-auto flex items-center">
          <IconButton
            size="md"
            className={clsx(
              'h-48 w-50 cursor-pointer text-center text-lg leading-10 hover:bg-alt',
              disablePrev && 'opacity-50',
            )}
            onClick={() => changeSliderItem(-1)}
            disabled={disablePrev}
          >
            <KeyboardArrowLeftIcon />
          </IconButton>
          <IconButton
            size="md"
            className={clsx(
              'h-40 w-50 cursor-pointer text-center text-lg leading-10 hover:bg-alt',
              disableNext && 'opacity-50',
            )}
            onClick={() => changeSliderItem(1)}
            disabled={disableNext}
          >
            <KeyboardArrowRightIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
