import clsx from 'clsx';
import {ReactNode} from 'react';

interface Props {
  id: string;
  title: ReactNode;
  titleSuffix?: ReactNode;
  children: ReactNode;
  actions?: ReactNode;
  className?: string;
  contentPadding?: string;
}
export function AccountSettingsPanel({
  id,
  title,
  titleSuffix,
  children,
  actions,
  className,
  contentPadding = 'p-24',
}: Props) {
  return (
    <section
      id={id}
      className={clsx(
        'mb-24 flex w-full flex-col overflow-hidden rounded-panel border bg shadow',
        className,
      )}
    >
      <div className="flex items-center gap-14 border-b bg-alt px-16 py-10 md:px-24">
        <div className="text-md font-semibold">{title}</div>
        {titleSuffix && <div className="ml-auto">{titleSuffix}</div>}
      </div>
      <div className={clsx('relative flex flex-1 flex-col', contentPadding)}>
        {children}
      </div>
      {actions && (
        <div className="flex justify-end border-t px-16 py-16 md:px-24">
          {actions}
        </div>
      )}
    </section>
  );
}
