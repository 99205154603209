import clsx from 'clsx';
import {CustomMenu} from '../../menus/custom-menu';
import {LocaleSwitcher} from '../../i18n/locale-switcher';
import {Button} from '../buttons/button';
import {DarkModeIcon} from '../../icons/material/DarkMode';
import {LightbulbIcon} from '../../icons/material/Lightbulb';
import {Trans} from '../../i18n/trans';
import {useThemeSelector} from '../themes/theme-selector-context';
import {useSettings} from '../../core/settings/use-settings';

interface Props {
  container?: boolean;
  className?: string;
  padding?: string;
}

export function Footer({container, className, padding}: Props) {
  const year = new Date().getFullYear();
  const {branding} = useSettings();
  return (
    <footer
      className={clsx(
        'shadow-footer bg text-sm',
        padding ? padding : 'px-12 pb-12 pt-16 md:px-24 md:pb-16',
        className,
      )}
    >
      <div
        className={clsx(container && 'container mx-auto flex-shrink-0 px-24')}
      >
        <Menus />
        <div className="items-center justify-between gap-30 text-center text-muted md:flex md:text-left">
          <Trans
            message="Copyright © :year :name, All Rights Reserved"
            values={{year, name: branding.site_name}}
          />
          <div>
            <ThemeSwitcher />
            <LocaleSwitcher />
          </div>
        </div>
      </div>
    </footer>
  );
}

function Menus() {
  const {menus} = useSettings();
  const primaryMenu = menus && menus.find(m => m.positions?.includes('footer'));
  const secondaryMenu =
    menus && menus.find(m => m.positions?.includes('footer-secondary'));

  if (!primaryMenu && !secondaryMenu) return null;

  return (
    <div className="mb-14 flex flex-col items-center justify-between gap-30 overflow-x-auto border-b pb-14 md:flex-row">
      {primaryMenu && (
        <CustomMenu menu={primaryMenu} className="text-primary" />
      )}
      {secondaryMenu && (
        <CustomMenu menu={secondaryMenu} className="text-muted" />
      )}
    </div>
  );
}

function ThemeSwitcher() {
  const {themes} = useSettings();
  const {selectedTheme, selectTheme} = useThemeSelector();
  if (!selectedTheme || !themes?.user_change) return null;

  return (
    <Button
      variant="text"
      startIcon={selectedTheme.is_dark ? <DarkModeIcon /> : <LightbulbIcon />}
      onClick={() => {
        if (selectedTheme.is_dark) {
          selectTheme('light');
        } else {
          selectTheme('dark');
        }
      }}
    >
      {selectedTheme.is_dark ? (
        <Trans message="Light mode" />
      ) : (
        <Trans message="Dark mode" />
      )}
    </Button>
  );
}
