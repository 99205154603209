import {useQuery} from '@tanstack/react-query';
import {apiClient} from '@common/http/query-client';
import {BackendResponse} from '@common/http/backend-response/backend-response';
import {Item} from '@app/agent/item';
import {CustomPage} from '@common/admin/custom-pages/custom-page';
import {getBootstrapData} from '@common/core/bootstrap-data/use-backend-bootstrap-data';

export interface HomePageDataResponse extends BackendResponse {
  items: Item[];
  pages: CustomPage[];
}

export function useHomePage() {
  return useQuery<HomePageDataResponse>({
    queryKey: ['home-items'],
    queryFn: () => fetchContent(),
    initialData: getBootstrapData().loaders?.homePage,
  });
}

function fetchContent(): Promise<HomePageDataResponse> {
  return apiClient.get(`home-items`).then(response => response.data);
}
