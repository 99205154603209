import {CustomPage} from '@common/admin/custom-pages/custom-page';
import {useEffect, useRef} from 'react';
import {highlightAllCode} from '@common/text-editor/highlight/highlight-code';
import {FormattedDate} from '@common/i18n/formatted-date';
import {FacebookIcon} from '@common/icons/social/facebook';
import {TwitterIcon} from '@common/icons/social/twitter';
import {EmailIcon} from '@common/icons/material/Email';
import {Tooltip} from '@common/ui/tooltip/tooltip';
import {message} from '@common/i18n/message';
import {useTrans} from '@common/i18n/use-trans';
import {Trans} from '@common/i18n/trans';
import {IconButton} from '@common/ui/buttons/icon-button';
import {CopyAllIcon} from '@common/icons/material/CopyAll';
import {shareLinkSocially} from '@common/utils/urls/share-link-socially';
import useClipboard from 'react-use-clipboard';
import {getBootstrapData} from '@common/core/bootstrap-data/use-backend-bootstrap-data';

interface CustomPageBodyProps {
  page: CustomPage;
}
export function CustomPageBody({page}: CustomPageBodyProps) {
  const bodyRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (bodyRef.current) {
      highlightAllCode(bodyRef.current);
    }
  }, []);

  return (
    <div className="bg-alt lg:px-24 lg:py-80">
      <div className="custom-page-body prose mx-auto max-w-5xl rounded-lg bg px-16 py-30 dark:prose-invert lg:prose-lg prose-a:text-primary prose-a:no-underline hover:prose-a:underline lg:px-80 lg:py-60">
        <h1 className="lg:text-center lg:leading-relaxed">{page.title}</h1>

        <div className="mb-40 mt-20 text-xs tracking-widest text-disabled lg:text-center">
          <FormattedDate date={page.created_at} preset="short" />
        </div>
        <div
          ref={bodyRef}
          className="whitespace-pre-wrap break-words"
          dangerouslySetInnerHTML={{__html: page.body}}
        />
        <ShareButtons
          link={`${getBootstrapData().settings.base_url}/pages/${page.slug}`}
          text={page.title}
        />
      </div>
    </div>
  );
}

function ShareButtons({link, text}: {link: string; text?: string}) {
  const {trans} = useTrans();
  const [isCopied, copyToClipboard] = useClipboard(link, {
    successDuration: 2000,
  });

  return (
    <div className="mt-50 flex flex-col justify-center gap-10">
      <div className="text-center text-xs tracking-widest text-disabled">
        <Trans message="SHARE THIS ARTICLE" />
      </div>
      <div className="flex justify-center gap-10">
        <Tooltip label={trans(message('Share with Facebook'))} delay={10}>
          <IconButton
            variant="outline"
            className="text-facebook"
            size="md"
            onClick={() => shareLinkSocially('facebook', link, text)}
          >
            <FacebookIcon />
          </IconButton>
        </Tooltip>
        <Tooltip label={trans(message('Share with Twitter'))} delay={10}>
          <IconButton
            variant="outline"
            className="text-twitter"
            size="md"
            onClick={() => shareLinkSocially('twitter', link, text)}
          >
            <TwitterIcon />
          </IconButton>
        </Tooltip>
        <Tooltip label={trans(message('Share via Email'))} delay={10}>
          <IconButton
            variant="outline"
            className="text-danger"
            size="md"
            onClick={() => shareLinkSocially('mail', link, text)}
          >
            <EmailIcon />
          </IconButton>
        </Tooltip>
        <Tooltip
          label={
            isCopied ? trans(message('Copied!')) : trans(message('Copy link'))
          }
          delay={0}
        >
          <IconButton
            variant="outline"
            className="text-positive"
            size="md"
            onClick={copyToClipboard}
          >
            <CopyAllIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
}
