import {Trans} from '@common/i18n/trans';
import {User} from '@common/auth/user';
import {AccountSettingsPanel} from '@common/account/settings/account-settings-panel';
import {UseQueryResult} from '@tanstack/react-query';
import {ProgressCircle} from '@common/ui/progress/progress-circle';
import {PageErrorMessage} from '@common/errors/page-error-message';
import {IllustratedMessage} from '@common/ui/images/illustrated-message';
import {Button} from '@common/ui/buttons/button';

import {Table} from '@common/ui/tables/table';
import {useNavigate} from '@common/utils/hooks/use-navigate';
import {
  PaginateTicketsResponse,
  useDashboardTickets,
} from '@app/agent/agent-ticket-page/user-details-sidebar/requests/use-tickets';
import {Ticket} from '@app/agent/ticket';
import {ColumnConfig} from '@common/datatable/column-config';
import {FormattedRelativeTime} from '@common/i18n/formatted-relative-time';
import {Link} from 'react-router-dom';
import searchImage from '@common/icons/no-results.png';
import {KeyboardArrowRightIcon} from '@common/icons/material/KeyboardArrowRight';

const DashboardTicketTableColumns: ColumnConfig<Ticket>[] = [
  {
    key: 'subject',
    visibleInMode: 'all',
    header: () => <Trans message="Subject" />,
    body: ticket => ticket.subject,
    width: 'flex-3 min-w-200',
  },
  {
    key: 'updated_at',
    visibleInMode: 'all',
    header: () => <Trans message="Last updated" />,
    width: 'w-120',
    body: ticket => <FormattedRelativeTime date={ticket.updated_at} />,
  },
];

interface Props {
  user: User;
}
export function AccountDashboardTicketsPanel({user}: Props) {
  const query = useDashboardTickets({
    userId: user!.id,
    perPage: 7,
    loader: 'basic',
  });

  return (
    <AccountSettingsPanel
      id="tickets"
      title={<Trans message="Latest Tickets" />}
      contentPadding="p-0"
    >
      <Content query={query} />
    </AccountSettingsPanel>
  );
}

interface ContentProps {
  query: UseQueryResult<PaginateTicketsResponse>;
}
function Content({query}: ContentProps) {
  const navigate = useNavigate();

  if (query.data) {
    const data = query.data?.pagination?.data || [];

    if (!data.length) {
      return (
        <div>
          <IllustratedMessage
            size="sm"
            className="my-50"
            imageHeight="h-auto"
            title={
              <span className="text-muted">
                <Trans message="Nothing to show." />
              </span>
            }
            imageMargin="mb-16"
            action={
              <Button
                variant="link"
                color="primary"
                onClick={() => navigate('/account/tickets/new')}
              >
                <Trans message="Create a new ticket?" />
              </Button>
            }
            image={<img src={searchImage} className="mx-auto w-160" />}
          />
        </div>
      );
    }
    return (
      <>
        <Table
          columns={DashboardTicketTableColumns}
          data={query.data.pagination.data}
          enableSelection={false}
          onAction={item => navigate(`/account/tickets/${item.id}`)}
          cellHeight="h-60"
        />
        <Link
          to="/account/tickets"
          className="mt-auto flex min-w-0 border-t px-16 py-12 text-sm text-muted hover:bg-alt hover:text-main md:px-24"
        >
          <Trans message="See all tickets" />
          <KeyboardArrowRightIcon className="ml-auto text-inherit" />
        </Link>
      </>
    );
  }

  if (query.isLoading) {
    return (
      <div className="flex min-h-82 items-center justify-center">
        <ProgressCircle isIndeterminate />
      </div>
    );
  }

  return <PageErrorMessage />;
}
