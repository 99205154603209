import {Trans} from '@common/i18n/trans';
import {User} from '@common/auth/user';
import {AccountSettingsPanel} from '@common/account/settings/account-settings-panel';
import {useUserDashboardPurchases} from '@app/account/licenses/use-user-purchases';
import {UseQueryResult} from '@tanstack/react-query';
import {ProgressCircle} from '@common/ui/progress/progress-circle';
import {PageErrorMessage} from '@common/errors/page-error-message';
import React, {Fragment} from 'react';
import {FormattedDate} from '@common/i18n/formatted-date';
import {IllustratedMessage} from '@common/ui/images/illustrated-message';
import {EnvatoIcon} from '@common/icons/social/envato';
import {License} from '@app/agent/license';
import {LengthAwarePaginationResponse} from '@common/http/backend-response/pagination-response';
import {Table} from '@common/ui/tables/table';
import {Link, useNavigate} from 'react-router-dom';
import {ColumnConfig} from '@common/datatable/column-config';
import {Tooltip} from '@common/ui/tooltip/tooltip';
import {KeyboardArrowRightIcon} from '@common/icons/material/KeyboardArrowRight';

const DashboardLicensesTableColumns: ColumnConfig<License>[] = [
  {
    key: 'item',
    header: () => <Trans message="Item" />,
    width: 'w-40 flex-shrink-0',
    body: license =>
      license.item ? (
        <Tooltip label={license.item.name} delay={0}>
          <img className="h-38 w-38 rounded" src={license.item.icon} alt="" />
        </Tooltip>
      ) : (
        '-'
      ),
  },
  {
    key: 'code',
    visibleInMode: 'all',
    width: 'flex-3 min-w-200',
    header: () => <Trans message="License" />,
    body: entry => (
      <Fragment>
        <a
          href={'//' + entry.url}
          target="_blank"
          rel="noreferrer"
          className="overflow-x-hidden overflow-ellipsis font-semibold hover:underline"
        >
          {entry.code}
        </a>
        <div className="overflow-x-hidden overflow-ellipsis text-xs text-disabled">
          {entry.license}
        </div>
      </Fragment>
    ),
  },
  {
    key: 'supported_until',
    allowsSorting: true,
    width: 'w-100',
    header: () => <Trans message="Supported Until" />,
    body: entry => (
      <span className="text-sm text-muted">
        <FormattedDate preset="short" date={entry.supported_until} />
      </span>
    ),
  },
];

interface Props {
  user: User;
}
export function AccountDashboardPurchasesPanel({}: Props) {
  const query = useUserDashboardPurchases('me', {perPage: 5});

  return (
    <AccountSettingsPanel
      id="purchases"
      title={<Trans message="Latest Licenses" />}
      contentPadding="p-0"
    >
      <Content query={query} />
    </AccountSettingsPanel>
  );
}

interface ContentProps {
  query: UseQueryResult<{pagination: LengthAwarePaginationResponse<License>}>;
}
function Content({query}: ContentProps) {
  if (query.data) {
    if (!query.data.pagination.data.length) {
      return (
        <div>
          <IllustratedMessage
            size="sm"
            className="my-50"
            imageHeight="h-auto"
            title={
              <span className="text-muted">
                <Trans message="Nothing to show." />
              </span>
            }
            imageMargin="mb-16"
            image={<EnvatoIcon viewBox="0 0 50 50" className="text-envato" />}
          />
        </div>
      );
    }
    return (
      <>
        <Table
          columns={DashboardLicensesTableColumns}
          data={query.data.pagination.data}
          enableSelection={false}
          cellHeight="h-60"
        />
        <Link
          to="/account/licenses"
          className="mt-auto flex min-w-0 border-t px-16 py-12 text-sm text-muted hover:bg-alt hover:text-main md:px-24"
        >
          <Trans message="See all licenses" />
          <KeyboardArrowRightIcon className="ml-auto text-inherit" />
        </Link>
      </>
    );
  }

  if (query.isLoading) {
    return (
      <div className="flex min-h-82 items-center justify-center">
        <ProgressCircle isIndeterminate />
      </div>
    );
  }

  return <PageErrorMessage />;
}
